import JSZip from "jszip";
import { saveAs } from "file-saver";

class ZipDownloader {
  private zip: JSZip;

  constructor() {
    this.zip = new JSZip();
  }

  async add(url: string): Promise<void> {
    const response = await fetch(url);
    const blob = await response.blob();

    let fileName = url.substring(url.lastIndexOf("/") + 1);

    const contentType = response.headers.get("Content-Type");
    if (contentType) {
      const extension = contentType.split("/")[1];
      if (extension && !fileName.includes(".")) {
        fileName += `.${extension}`;
      }
    }

    this.zip.file(fileName, blob);
  }

  async addMulti(urls: string[]): Promise<void> {
    const fetchPromises = urls.map(async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();

      let fileName = url.substring(url.lastIndexOf("/") + 1);

      const contentType = response.headers.get("Content-Type");
      if (contentType) {
        const extension = contentType.split("/")[1];
        if (extension && !fileName.includes(".")) {
          fileName += `.${extension}`;
        }
      }

      return { fileName, blob };
    });

    const files = await Promise.all(fetchPromises);

    files.forEach(({ fileName, blob }) => {
      this.zip.file(fileName, blob);
    });
  }

  file(name: string, content: string): void {
    this.zip.file(name, content);
  }

  async save(name: string): Promise<void> {
    const content = await this.zip.generateAsync({ type: "blob" });
    saveAs(content, `${name}.zip`);
  }
}

export default ZipDownloader;