import { Button, Form, Input, Select, Switch, Tooltip } from "antd";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Edit2, Plus, Trash2, Upload as UploadIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import { useEditAlbumApi } from "../../services/apis/album.api";
import { useGetMeApi } from "../../services/apis/user.api";
import { TAlbumDetailResponse } from "../../types/album.type";
import MessageModal from "../MessageModal";
import CheckLinkAccess from "./CheckLinkAccess";

const EditAlbum = ({
  album,
  onClick,
}: {
  album: TAlbumDetailResponse["data"];
  onClick?: () => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const userProfile = useGetMeApi();
  const mutation = useEditAlbumApi();
  const ability = useContext(AbilityContext);
  const isFaceFider = ability.can("can", PERMISSION.FACE_FIDER);
  const isDownloadImage = ability.can("can", PERMISSION.DOWNLOAD_IMAGE);
  const [uploadedFile, setUpLoadedFile] = useState<File>();
  const [validUploaded, setValidUploaded] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isCheckLinkAccess, setIsCheckLinkAccess] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOpenFile = () => {
    if (!!inputRef.current) inputRef.current.click();
  };
  const handleChange = (event: any) => {
    if (event?.target.files) {
      setUpLoadedFile(event?.target.files[0]);
    }
  };
  const commentPassword = Form.useWatch("comment_password", form);
  const isCommentable = Form.useWatch("is_commentable", form);
  const isProtectedAlbum = Form.useWatch("is_protectable", form);
  const isLimitLike = Form.useWatch("is_limit_Like", form);

  const onFinish = (values: any) => {
    if (!isCheckLinkAccess) return;
    if (uploadedFile && uploadedFile.size / 1024 / 1024 > 10) return;
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("customer_name", values?.customer_name);
    formData.append("name", values?.name);
    formData.append("folder_links[]", values?.folder_links1);
    if (uploadedFile) formData.append("image", uploadedFile);
    values?.folder_links?.forEach((item: any) => {
      if (item["folder_links"] !== undefined) {
        formData.append("folder_links[]", item["folder_links"]);
      }
    });
    formData.append("is_downloadable", `${+values?.is_downloadable}`);
    formData.append("is_commentable", `${+values?.is_commentable}`);
    formData.append("frequency", `${+values?.frequency}`);
    if (isProtectedAlbum) {
      formData.append("password", `${+values?.password}`);
    }
    formData.append("allow_face_finder", `${+values?.allow_face_finder}`);
    if (isCommentable) {
      formData.append("comment_password", commentPassword);
    }
    if (isLimitLike) {
      formData.append("max_file_likes", `${+values?.max_file_likes}`);
    }
    formData.append("is_limit_Like", `${+values?.is_limit_Like}`);
    mutation.mutate(
      {
        id: album?.id,
        dataQuery: formData,
      },
      {
        onSuccess(data) {
          if (data?.data?.success) {
            setIsModalOpen(true);
            onClick && onClick();
            userProfile.refetch();
          }
        },
        onError(error) {
          setError(t("formEditAlbum.editError.error"));
        },
      }
    );
  };
  const handleClick = () => {
    if (uploadedFile && uploadedFile.size / 1024 / 1024 > 10) {
      const temp = t("formEditAlbum.checkValidImage.size");
      setValidUploaded(temp);
    }
  };

  const options = useMemo(() => {
    return [
      { value: "", label: t("No") },
      { value: "5", label: "5 " + t("Minutes") },
      { value: "10", label: "10 " + t("Minutes") },
      { value: "20", label: "20 " + t("Minutes") },
      { value: "30", label: "30 " + t("Minutes") },
      { value: "60", label: "60 " + t("Minutes") },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckLinkAccess = (value: boolean) => {
    setIsCheckLinkAccess(value);
  };
  useEffect(() => {
    setValidUploaded("");
  }, [uploadedFile]);

  return (
    <>
      <Form
        form={form}
        name="create-album-form"
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        colon={false}
        initialValues={{
          is_downloadable: album?.is_downloadable,
          is_commentable: album?.is_commentable,
          frequency: album?.frequency ? album?.frequency + "" : "",
          allow_face_finder: album?.allow_face_finder,
          is_protectable: album?.password,
          name: album?.name,
          customer_name: album?.customer_name,
          is_limit_Like: !!album?.max_file_likes,
          max_file_likes: album?.max_file_likes,
          folder_links1: album?.folders.shift()?.link,
          folder_links: album?.folders?.map((item) => item.link),
        }}
      >
        <div className="relative mb-4">
          <CheckLinkAccess
            name="folder_links1"
            label={t("formEditAlbum.titleLinkGgDive")}
            tooltip={t("formEditAlbum.tooltipLinkGgDive")}
            autoFocus={true}
            onCheckLinkAccess={handleCheckLinkAccess}
            required
          />
          <Form.List name="folder_links">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key} className="flex">
                    <CheckLinkAccess
                      name={[field.name, `folder_links`]}
                      className="mt-3"
                      onCheckLinkAccess={handleCheckLinkAccess}
                      defaultValue={form.getFieldValue("folder_links")[index]}
                    />
                    <Trash2
                      className="dynamic-delete-button ml-2 w-5 text-primary cursor-pointer mt-[26px]"
                      onClick={() => remove(field.name)}
                    />
                  </div>
                ))}
                <div
                  onClick={() => {
                    if (fields.length < 4) add();
                  }}
                  className={`bg-secondary text-white border-0 absolute top-10 -right-1 flex items-center justify-center w-7 h-7 rounded-md ${
                    fields.length === 4
                      ? "cursor-default opacity-50"
                      : "cursor-pointer"
                  }`}
                >
                  <Plus className="w-4" />
                </div>
              </>
            )}
          </Form.List>
        </div>
        <Form.Item
          name="name"
          label={t("formEditAlbum.titleNameAlbum")}
          rules={[
            {
              required: true,
              message: (
                <span className="text-xs mb-1 block">
                  {t("formEditAlbum.requiredNameAlbum")}
                </span>
              ),
            },
          ]}
        >
          <Input
            placeholder={t("formEditAlbum.placeholderNameAlbum")}
            className="py-2 px-3"
          />
        </Form.Item>
        <Form.Item
          name="customer_name"
          label={t("formEditAlbum.titleNameUser")}
          rules={[
            {
              required: true,
              message: (
                <span className="text-xs mb-1 block">
                  {t("formEditAlbum.requiredNameUser")}
                </span>
              ),
            },
          ]}
        >
          <Input
            placeholder={t("formEditAlbum.placeholderNameUser")}
            className="py-2 px-3"
          />
        </Form.Item>
        <div className="relative flex items-center h-36 cursor-pointer rounded-lg transition-all mb-6">
          <div className="w-1/2 relative before:content-['*'] before:text-[#ff4d4f] before:mr-1">
            {t("formEditAlbum.titlePicture")}
          </div>
          <input
            type="file"
            ref={inputRef}
            accept="image/gif, image/jpeg, image/png, image/jpg, image/webp"
            className="!hidden"
            onChange={handleChange}
          />
          <div className="relative w-full h-full">
            {uploadedFile ? (
              <div className="relative w-full h-full">
                <div
                  onClick={() => {
                    setUpLoadedFile(undefined);
                  }}
                  className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
                >
                  <Trash2 className="w-4" />
                </div>
                <img
                  src={URL.createObjectURL(uploadedFile)}
                  alt="uploaded"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ) : (
              <div className="relative w-full h-full flex justify-center items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg">
                {album?.thumbnail_url ? (
                  <>
                    <div
                      onClick={handleOpenFile}
                      className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
                    >
                      <Edit2 className="w-4" />
                    </div>
                    <img
                      src={album?.thumbnail_url}
                      alt="thumbnail_url-default"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </>
                ) : (
                  <UploadIcon className="w-5" />
                )}
              </div>
            )}
            <span className="absolute -bottom-[1.1rem] left-0 text-xs mb-1 block text-primary">
              {validUploaded}
            </span>
          </div>
        </div>
        <div className="flex sm:items-center w-full flex-col sm:flex-row my-1">
          <span className="block sm:w-56 text-left">
            {t("formCreateAlbum.freshTime")}
          </span>
          <Form.Item
            name="frequency"
            layout="horizontal"
            className="mb-1 flex-1"
          >
            <Select
              options={options}
              placeholder={t("formCreateAlbum.freshTime")}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:gap-2 relative sm:h-12">
          <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto">
            <span className="block sm:w-56 text-left w-full">
              {t("formCreateAlbum.allowComment")}
            </span>
            <Form.Item
              name="is_commentable"
              layout="horizontal"
              className="mb-0"
            >
              <Switch />
            </Form.Item>
          </div>
          {isCommentable && (
            <Form.Item
              name="comment_password"
              className="relative flex-1 w-full mb-2 sm:mb-0"
              rules={[
                {
                  required: isCommentable,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formCreateAlbum.requirePass")}
                    </span>
                  ),
                },
                {
                  min: 6,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formLogin.minPassword")}
                    </span>
                  ),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          )}
        </div>
        <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto sm:py-1">
          <span
            className={`block sm:w-56 text-left w-full ${
              !isFaceFider ? "text-gray-500" : ""
            }`}
          >
            {t("formCreateAlbum.allowFace")}
          </span>
          <Form.Item
            name="allow_face_finder"
            layout="horizontal"
            className="mb-0"
          >
            <Tooltip
              title={t("formCreateAlbum.recommended")}
              color={"#0bcdac"}
              key={"#0bcdac"}
            >
              <Switch disabled={!isFaceFider} />
            </Tooltip>
          </Form.Item>
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:gap-2 relative sm:h-12">
          <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto">
            <span className="block sm:w-56 text-left w-full">
              {t("formCreateAlbum.protectedAlbum")}
            </span>
            <Form.Item
              name="is_protectable"
              layout="horizontal"
              className="mb-0"
            >
              <Switch />
            </Form.Item>
          </div>
          {isProtectedAlbum && (
            <Form.Item
              name="password"
              className="relative flex-1 w-full mb-2 sm:mb-0"
              rules={[
                {
                  required: isProtectedAlbum,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formCreateAlbum.requirePass")}
                    </span>
                  ),
                },
                {
                  min: 6,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formLogin.minPassword")}
                    </span>
                  ),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          )}
        </div>
        <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto sm:py-1">
          <span
            className={`block sm:w-56 text-left w-full ${
              !isDownloadImage ? "text-gray-500" : ""
            }`}
          >
            {t("formCreateAlbum.allowDownload")}
          </span>
          <Form.Item
            name="is_downloadable"
            layout="horizontal"
            className="mb-0"
          >
            <Tooltip
              title={t("formCreateAlbum.recommended")}
              color={"#0bcdac"}
              key={"#0bcdac"}
            >
              <Switch disabled={!isDownloadImage} />
            </Tooltip>
          </Form.Item>
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:gap-2 relative sm:h-12">
          <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto sm:py-1">
            <span className="block sm:w-56 text-left w-full">
              {t("formCreateAlbum.limitedNumber")}
            </span>
            <Form.Item
              name="is_limit_Like"
              layout="horizontal"
              className="mb-0"
            >
              <Switch />
            </Form.Item>
          </div>
          {isLimitLike && (
            <Form.Item
              name="max_file_likes"
              className="relative flex-1 w-full mb-2 sm:mb-0"
            >
              <Input type="number" min={1} />
            </Form.Item>
          )}
        </div>

        <div className="flex gap-2 items-center justify-end mt-4">
          <Button
            type="default"
            htmlType="reset"
            className="transition-all text-gray-900"
            onClick={() => {
              onClick && onClick();
            }}
          >
            {t("formEditAlbum.btnCancel")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="transition-all"
            loading={mutation.isLoading}
            disabled={mutation.isLoading}
            onMouseDown={() => handleClick()}
          >
            {t("formEditAlbum.btnOk")}
          </Button>
        </div>
      </Form>
      <MessageModal
        isVisible={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={t("formEditAlbum.editSuccess.title")}
        subTitle={t("formEditAlbum.editSuccess.subTitle")}
        type="success"
        btnText={t("formEditAlbum.editSuccess.btn")}
      />

      <MessageModal
        isVisible={!!error}
        onClose={() => {
          setError(null);
        }}
        title={t("formEditAlbum.editError.title")}
        subTitle={error}
        type="error"
        btnText={t("formEditAlbum.editError.btn")}
      />
    </>
  );
};

export default EditAlbum;
