import { Button, Form, Input, Skeleton } from "antd";
import { useWatch } from "antd/es/form/Form";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListPackage from "../../components/ServicePackages/ListPackage";
import {
  useCalculateUpgradeApi,
  useGetDiscountApi,
  useGetMembershipPlansApi,
  useSubcriptionCreateApi,
} from "../../services/apis/payment.api";
import {
  TCreateSubmissions,
  TMembershipPlanPrice,
} from "../../types/membership.type";
import { useCheckUnit } from "../../utils/membership";

const ChooseService = ({
  onClick,
  values,
}: {
  onClick: (dataPayment: { total: number; subscriptionId: number }) => void;
  values?: TMembershipPlanPrice[];
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const checkUnit = useCheckUnit();
  const mutation = useSubcriptionCreateApi();
  const calculateUpgradeApi = useCalculateUpgradeApi();
  const {
    data: membershipPlans,
    isLoading: loadingMembershipPlans,
    isFetching: fetchingMembership,
  } = useGetMembershipPlansApi();
  const promoCode = useWatch("promo_code", form);
  const {
    data: discount,
    refetch: refetchDiscount,
    isLoading: loadingDiscount,
    isError: errorDiscount,
    isFetching: fetchingDiscount,
  } = useGetDiscountApi(promoCode);
  const [membershipPlanPriceId, setMembershipPlanPriceId] = useState<number>();
  const [total, setTotal] = useState<number>();
  const [error, setError] = useState<ReactNode | null>(null);
  const [checkMode, setCheckMode] = useState<"check" | "edit">("check");
  const [totalFinal, setTotalFinal] = useState<number>(undefined);

  const [refund, setRefund] = useState<number>(0);

  useEffect(() => {
    console.log(calculateUpgradeApi?.data?.data?.currentSubscription.membership_plan_id, values[0].membership_plan_id);
    if (
      calculateUpgradeApi?.data?.data?.currentSubscription.membership_plan_id !==
      values[0]?.membership_plan_id
    ) {
      setRefund(calculateUpgradeApi.data?.data.refund || 0);
    }
    else {
      setRefund(0);
    }
  }, [calculateUpgradeApi, values]);

  const onFinish = (values: any) => {
    const dataCreateSubmissions: TCreateSubmissions = {
      membership_plan_price_id: membershipPlanPriceId,
      promo_code:
        values?.promo_code && totalFinal ? values?.promo_code : undefined,
    };
    mutation.mutate(dataCreateSubmissions, {
      onSuccess: (data) => {
        onClick &&
          onClick({
            subscriptionId: data?.data?.subscription?.id,
            total: totalFinal ? totalFinal : total,
          });
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    form.resetFields();
    setCheckMode("check");
    setError(null);
    setTotal(values[0]?.[checkUnit]);
    setMembershipPlanPriceId(values[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, fetchingMembership]);

  useEffect(() => {
    setTotalFinal(total - refund);
  }, [total, refund])

  useEffect(() => {
    if (errorDiscount && !fetchingDiscount && !!promoCode) {
      setError(
        <span className="text-xs block text-primary">
          {t("servicePackages.payment.invalidCode")}
        </span>
      );
      setTotalFinal(total - refund);
    } else if (discount?.status === 200 && !fetchingDiscount) {
      setError(
        <span className="text-xs block text-secondary">
          {"-" + discount?.data?.percent + "%"}
        </span>
      );
      setTotalFinal((total - refund) * ((100 - discount?.data?.percent) / 100));
      setCheckMode("edit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingDiscount]);

  return (
    <>
      {loadingMembershipPlans ? (
        <Skeleton active className="h-64" />
      ) : (
        <Form
          form={form}
          name="payment-form"
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          colon={false}
        >
          <ListPackage
            keyRefetch={values[0]?.id}
            listPlanPrice={values}
            membershipPlans={membershipPlans?.data}
            onClick={({ membershipPlanPriceId, price }) => {
              setMembershipPlanPriceId(membershipPlanPriceId);
              setTotal(price);
            }}
          />
          <div className="relative w-full flex flex-col mb-6">
            <Form.Item
              label={t("servicePackages.payment.code")}
              name="promo_code"
              className="mb-1"
            >
              <div className="flex gap-2 items-center">
                <Input
                  className="flex-1"
                  disabled={checkMode === "edit"}
                  onInput={(e) => {
                    if (e?.currentTarget) {
                      e.currentTarget.value = e.currentTarget.value?.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      );
                    }
                  }}
                />
                <Button
                  type="default"
                  className={`transition-all success ${
                    !promoCode?.trim() ? "opacity-60 hover:!opacity-60" : ""
                  }`}
                  onClick={() => {
                    if (checkMode === "edit") {
                      setCheckMode("check");
                      return;
                    }
                    refetchDiscount();
                  }}
                  disabled={loadingDiscount || !promoCode?.trim()}
                  loading={loadingDiscount}
                >
                  {checkMode === "edit"
                    ? t("servicePackages.payment.editCode")
                    : t("servicePackages.payment.checkCode")}
                </Button>
              </div>
            </Form.Item>
            {!!error && (
              <span className="text-xs block text-primary">{error}</span>
            )}
          </div>
          <div className={`flex ${!totalFinal ? "items-center" : "items-end"}`}>
            {t("servicePackages.payment.total") + ":"}
            <span
              className={`block ml-2 ${
                totalFinal === total
                  ? "text-lg text-primary"
                  : " text-base line-through text-gray-600"
              }`}
            >
              {total?.toLocaleString("de-DE") +
                t("servicePackages.payment.priceUnit")}
            </span>
            {totalFinal !== total && (
              <span className="text-primary text-xl ml-3">
                {totalFinal?.toLocaleString("de-DE") +
                  t("servicePackages.payment.priceUnit")}
              </span>
            )}
          </div>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              className="success transition-all mt-6 font-medium !py-3"
              loading={mutation.isLoading}
              disabled={mutation.isLoading}
              size="middle"
            >
              {t("servicePackages.payment.btn")}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default ChooseService;
