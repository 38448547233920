import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function DocumentTitle({ keyPath = 1 }: { keyPath?: number }) {
  const location = useLocation();
  const pathname = location.pathname.split('/')[keyPath];
  const { t } = useTranslation();

  useEffect(() => {
    if (!!pathname) {
      const title = t(`title.${pathname}`);
      document.title = `Shotpik - ${title}`;
    } else document.title = 'Shotpik';
  }, [pathname, t]);

  return null;
}
