import * as faceapi from "face-api.js";
import { TImage } from "../types/album.type";

export const resizeImageWithAspectRatio = (
  image: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
): HTMLCanvasElement => {
  const aspectRatio = image.width / image.height;

  let width = maxWidth;
  let height = maxHeight;

  if (width / height > aspectRatio) {
    width = height * aspectRatio;
  } else {
    height = width / aspectRatio;
  }

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.drawImage(image, 0, 0, width, height);
  }

  return canvas;
};

let modelsLoaded = false;

export const loadModels = async () => {
  if (!modelsLoaded) {
    await faceapi.loadSsdMobilenetv1Model("/models");
    await faceapi.loadFaceLandmarkModel("/models");
    await faceapi.loadFaceRecognitionModel("/models");
    modelsLoaded = true;
  }
};

export const loadLabeledImages = async (image: File) => {
  const descriptions = [];

  const img = await faceapi.bufferToImage(image);

  // Resize ảnh trước khi nhận diện
  const resizedCanvas = resizeImageWithAspectRatio(img, 500, 500);

  const detections = await faceapi
    .detectSingleFace(resizedCanvas)
    .withFaceLandmarks()
    .withFaceDescriptor();

  if (detections) {
    descriptions.push(detections.descriptor);
  }

  return new faceapi.LabeledFaceDescriptors("Label", descriptions);
};

export const checkLabeledImages = async (
  images: TImage[],
  labeledFaceDescriptor: faceapi.LabeledFaceDescriptors
) => {
  const checkImagePromises = images.map(async (image) => {
    const img = await faceapi.fetchImage(image.url);

    const resizedCanvas = resizeImageWithAspectRatio(img, 500, 500);

    const detections = await faceapi
      .detectAllFaces(resizedCanvas)
      .withFaceLandmarks()
      .withFaceDescriptors();

    for (const detection of detections) {
      const distance = faceapi.euclideanDistance(
        detection.descriptor,
        labeledFaceDescriptor.descriptors[0]
      );
      console.log(image.file_name, distance);
      if (distance < 0.5) {
        return image.id;
      }
    }

    return null;
  });

  const results = await Promise.all(checkImagePromises);
  return results.filter((url) => url !== null) as string[];
};
