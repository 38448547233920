import { createSlice } from '@reduxjs/toolkit';
import { TAlbumStore } from '../../types/album.type';

const initialState: TAlbumStore = {
  isOpenCreateAlbum: false,
  isCreateSuccess: false,
  isDeleteSuccess: false,
};

const albumSlice = createSlice({
  name: 'album',
  initialState: initialState,
  reducers: {
    setIsOpenCreateAlbum: (state, action) => {
      return {
        ...state,
        isOpenCreateAlbum: action.payload,
      };
    },
    setIsCreateSuccess: (state, action) => {
      return {
        ...state,
        isCreateSuccess: action.payload,
      };
    },
    setIsDeleteSuccess: (state, action) => {
      return {
        ...state,
        isDeleteSuccess: action.payload,
      };
    },
  },
});

export const { setIsOpenCreateAlbum, setIsCreateSuccess, setIsDeleteSuccess } =
  albumSlice.actions;

export default albumSlice.reducer;
