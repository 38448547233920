import { RouterProvider } from 'react-router-dom';
import { routes } from './routes';
import './App.css';
import Stores from './stores/Stores';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import Login from './pages/Login';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 10000,
      },
    },
  });

  return (
    <ErrorBoundary
      FallbackComponent={() => {
        return <Login />;
      }}
    >
      <Stores>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={routes} />;
        </QueryClientProvider>
      </Stores>
    </ErrorBoundary>
  );
}

export default App;
