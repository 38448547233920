import { Button, Form, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Trash2, Upload } from 'react-feather';
import { useTranslation } from 'react-i18next';
import MessageModal from '../../components/MessageModal';
import { useGetMeApi, useUpdateProfileApi } from '../../services/apis/user.api';
import { useRootSelector } from '../../stores/reducers/root';

export default function Profile() {
  const { t } = useTranslation();
  const userProfile = useGetMeApi();

  const [uploadedFile, setUpLoadedFile] = useState<File | string>();

  const [validUploaded, setValidUploaded] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [form] = Form.useForm();
  const user = useRootSelector((state) => state.user.userProfile);
  const [error, setError] = useState<string | null>(null);
  const [notification, setNotification] = useState<string | null>(null);

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
      website: user?.website,
      facebook: user?.facebook,
      description: user?.description,
      address: user?.address,
      phone_number: user?.phone_number,
      password: '',
      'confirm-password': '',
      'new-password': '',
    });
    setUpLoadedFile(user?.avatar_url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const mutation = useUpdateProfileApi();

  const onFinish = (values: any) => {
    const formData = new FormData();
    if (typeof uploadedFile === 'object') {
      formData.append('image', uploadedFile);
    }

    formData.append('name', values.name);
    formData.append('website', values.website);
    formData.append('facebook', values.facebook);
    formData.append('password', values.password);
    formData.append('new_password', values['new-password']);
    formData.append('_method', 'PUT');

    // if (!!inputRef.current) inputRef.current.value = '';

    mutation.mutate(formData, {
      onSuccess: (res) => {
        setError('');
        setNotification(t('formProfile.success'));
        userProfile.refetch();
      },
      onError: (error) => {
        setError(error.response?.data.message || t('formProfile.error'));
        setNotification('');
      },
    });
  };

  const handleOpenFile = () => {
    if (!!inputRef.current) inputRef.current.click();
  };

  const handleChange = (event: any) => {
    if (event?.target.files) {
      setUpLoadedFile(event?.target.files[0]);
    }
  };

  const handleClick = () => {
    setError('');
    setNotification('');
    if (typeof uploadedFile === 'string') {
      return;
    } else {
      if (uploadedFile?.size / 1024 / 1024 > 10) {
        const temp = t('formCreateAlbum.checkValidImage.size');
        setValidUploaded(temp);
      }
    }
  };

  return (
    <>
      <div className="relative py-8 flex flex-col items-center md:max-w-2xl m-auto">
        <div className="text-slate-700 font-semibold text-xl sm:text-2xl">
          {t('formProfile.title')}
        </div>
        <div className="relative w-full h-[1px] bg-slate-900/30 text-slate-500 my-5 text-sm">
          <span className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-white px-3">
            {t('formProfile.title')}
          </span>
        </div>
        <Form
          form={form}
          name="register-form"
          layout="vertical"
          className="w-full flex flex-col"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label={t('formProfile.brand')}
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredName')}
                  </span>
                ),
              },
            ]}
          >
            <Input
              autoFocus
              placeholder={t('formRegister.name')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item name="description" label={t('formProfile.description')}>
            <Input type="text" className="py-2 px-3" />
          </Form.Item>
          <Form.Item name="email" label={t('formProfile.email')}>
            <Input type="text" className="py-2 px-3" disabled />
          </Form.Item>
          <Form.Item name="phone_number" label={t('formProfile.phone')}>
            <Input type="text" className="py-2 px-3" />
          </Form.Item>
          <Form.Item name="address" label={t('formProfile.address')}>
            <Input type="text" className="py-2 px-3" />
          </Form.Item>
          <Form.Item label={t('formRegister.website')} name="website">
            <Input
              placeholder={t('formRegister.website')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item name="facebook" label={t('formRegister.facebook')}>
            <Input
              type="text"
              placeholder={t('formRegister.facebook')}
              className="py-2 px-3"
            />
          </Form.Item>
          <div className="relative flex items-center cursor-pointer rounded-lg transition-all mb-6">
            <div className="flex-1 w-full relative before:content-['*'] before:text-[#ff4d4f] before:mr-1">
              {t('formProfile.avatar')}
            </div>
            <input
              type="file"
              ref={inputRef}
              accept="image/gif, image/jpeg, image/png, image/jpg, image/webp"
              className="!hidden"
              onChange={handleChange}
            />
            <div className="relative w-32 h-32">
              {uploadedFile ? (
                <div className="absolute w-full h-full">
                  <div
                    onClick={() => {
                      setUpLoadedFile(undefined);
                    }}
                    className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
                  >
                    <Trash2 className="w-4" />
                  </div>
                  <img
                    src={
                      typeof uploadedFile === 'string'
                        ? uploadedFile
                        : URL.createObjectURL(uploadedFile)
                    }
                    alt="uploaded"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              ) : (
                <div
                  onClick={handleOpenFile}
                  className="absolute w-full h-full flex justify-center items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg"
                >
                  <Upload className="w-5" />
                </div>
              )}
              <span className="absolute -bottom-6 left-0 text-xs mb-1 block text-primary">
                {validUploaded}
              </span>
            </div>
          </div>
          <div className="relative w-full h-[1px] bg-slate-900/30 text-slate-500 my-5 text-sm">
            <span className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-white px-3 w-max">
              {t('formProfile.changePass')}
            </span>
          </div>
          <Form.Item
            label={t('formRegister.password')}
            name="password"
            rules={[
              {
                required: false,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredPassword')}
                  </span>
                ),
              },
              {
                min: 6,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.minPassword')}
                  </span>
                ),
              },
            ]}
          >
            <Input
              type="password"
              placeholder={t('formRegister.password')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            label={t('formProfile.newPass')}
            name="new-password"
            rules={[
              {
                required: false,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredPassword')}
                  </span>
                ),
              },
              {
                min: 6,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.minPassword')}
                  </span>
                ),
              },
              {
                validator: (_, value) => {
                  const passwordValue = form.getFieldValue('password');
                  if (passwordValue && !value) {
                    return Promise.reject(
                      new Error(t('formRegister.requiredPassword'))
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="password"
              placeholder={t('formProfile.newPass')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="confirm-password"
            label={t('formRegister.confirmPassword')}
            dependencies={['new-password']}
            rules={[
              {
                message: 'Please confirm your new password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const passwordValue = form.getFieldValue('new-password');
                  if (passwordValue && !value) {
                    return Promise.reject(
                      new Error(t('formRegister.requiredPassword'))
                    );
                  }
                  if (!value || passwordValue === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('formRegister.notMatchPassword'))
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder={t('formRegister.confirmPassword')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              className="transition-all mt-4 font-medium"
              size="large"
              loading={mutation.isLoading}
              disabled={mutation.isLoading}
              onMouseDown={() => handleClick()}
            >
              {t('formProfile.btn')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <MessageModal
        isVisible={!!error || !!notification}
        onClose={() => {
          setError(null);
          setNotification(null);
        }}
        title={!!error ? error : notification}
        subTitle={error}
        type={!!error ? 'error' : 'success'}
        btnText={t('btnClose')}
      />
    </>
  );
}
