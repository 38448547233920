import { Dropdown } from 'antd';
import { Globe } from 'react-feather';
import { useTranslation } from 'react-i18next';
import vnIcon from '../../assets/images/vn.png';
import enIcon from '../../assets/images/en.png';
import { setLanguageToLS } from '../../utils/localStorage';

const Languages = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguageToLS(lng);
  };

  const items = [
    {
      key: "en",
      label: <button>{t("languages.en")}</button>,
      icon: <img alt="icon-en" src={enIcon} className="w-6" />,
    },
    {
      key: "vi",
      label: <button>{t("languages.vi")}</button>,
      icon: <img alt="icon-vn" src={vnIcon} className="w-6" />,
    },
  ];

  const onClick = ({ key }: { key: string }) => {
    changeLanguage(key);
  };

  return (
    <Dropdown
      menu={{
        items,
        onClick,
      }}
    >
      <Globe className="w-5" />
    </Dropdown>
  );
};

export default Languages;
