import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HeaderHome from '../components/Header/HeaderHome';

const HomeLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <>
      <HeaderHome />
      <Outlet />
    </>
  );
};

export default HomeLayout;
