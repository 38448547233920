import { useEffect, useState } from 'react';
import ChooseService from './ChooseService';
import Payment from './Payment';
import { TMembershipPlanPrice } from '../../types/membership.type';

const ModalPayment = ({ values }: { values?: TMembershipPlanPrice[] }) => {
  const [step, setStep] = useState<{
    subscriptionId: number;
    total: number;
  } | null>(null);

  useEffect(() => {
    setStep(null);
  }, [values]);

  return !step ? (
    <ChooseService
      onClick={({ subscriptionId, total }) => {
        setStep({ subscriptionId: subscriptionId, total: total });
      }}
      values={values}
    />
  ) : (
    <Payment subscriptionId={step?.subscriptionId} total={step?.total} />
  );
};

export default ModalPayment;
