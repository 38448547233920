import { Navigate, createBrowserRouter } from 'react-router-dom';
import ConfirmPassword from '../components/ConfirmPassword';
import DocumentTitle from '../components/DocumentTitle/DocumentTitle';
import AuthenLayout from '../layouts/AuthenLayout';
import HomeLayout from '../layouts/HomeLayout';
import MainLayout from '../layouts/MainLayout';
import Album from '../pages/Album';
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Home';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Profile from '../pages/Profile';
import Setting from '../pages/Profile/setting';
import Register from '../pages/Register';
import ForgotPassword from '../pages/ResetPassword';
import ResetPassword from '../pages/ResetPassword/reset';
import ServicePackages from '../pages/ServicePackages';
import StaticPage from '../pages/StaticPage';
import { useRootSelector } from '../stores/reducers/root';
import { path } from './path';
import PackageManagement from '../pages/PackageManagement';

const RejectRoute = () => {
  const isAuthenticated = useRootSelector(
    (state) => state.auth.isAuthenticated
  );
  return isAuthenticated ? <Navigate to={path.dashboard} /> : <AuthenLayout />;
};

const AuthRoute = () => {
  const isAuthenticated = useRootSelector(
    (state) => state.auth.isAuthenticated
  );
  return isAuthenticated ? <MainLayout /> : <Navigate to={path.home} />;
};

export const routes = createBrowserRouter([
  {
    path: '',
    element: <HomeLayout />,
    children: [
      {
        path: path.home,
        element: (
          <>
            <DocumentTitle />
            <Home />
          </>
        ),
      },
      {
        path: path.servicepackages,
        element: (
          <>
            <DocumentTitle />
            <ServicePackages />
          </>
        ),
      },
      {
        path: path.staticpage,
        element: <StaticPage />,
      },
    ],
  },
  {
    path: path.album,
    element: (
      <>
        <DocumentTitle />
        <AuthenLayout>
          <ConfirmPassword />
        </AuthenLayout>
      </>
    ),
  },
  {
    path: '',
    element: <RejectRoute />,
    children: [
      {
        path: path.login,
        element: (
          <>
            <DocumentTitle />
            <Login />
          </>
        ),
      },
      {
        path: path.register,
        element: (
          <>
            <DocumentTitle />
            <Register />
          </>
        ),
      },
      {
        path: path.reset,
        element: (
          <>
            <DocumentTitle />
            <ResetPassword />
          </>
        ),
      },
      {
        path: path.recovery,
        element: (
          <>
            <DocumentTitle />
            <ForgotPassword />
          </>
        ),
      },
    ],
  },
  {
    path: '',
    element: <AuthRoute />,
    children: [
      {
        path: path.dashboard,
        element: (
          <>
            <DocumentTitle />
            <Dashboard />
          </>
        ),
      },
      {
        path: path.albumUser,
        element: (
          <>
            <DocumentTitle keyPath={2} />
            <Album />
          </>
        ),
      },
      {
        path: path.packageManagement,
        element: (
          <>
            <DocumentTitle keyPath={2} />
            <PackageManagement />
          </>
        ),
      },
      {
        path: path.profile,
        element: (
          <>
            <DocumentTitle keyPath={2} />
            <Profile />
          </>
        ),
      },
      {
        path: path.setting,
        element: (
          <>
            <DocumentTitle keyPath={2} />
            <Setting />
          </>
        ),
      },
      {
        path: path.album,
        element: (
          <>
            <DocumentTitle keyPath={2} />
            <Album />
          </>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
