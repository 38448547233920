import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  paymentPaypalCheck,
  paymentPaypalCreate,
} from "../../services/apis/payment.api";
import MessageModal, { TMessageModal } from "../../components/MessageModal";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsCloseAllModal } from "../../stores/reducers/payment.reducer";

const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export default function PayPal({ subscriptionId }: { subscriptionId: number }) {
  const [isModalOpen, setIsModalOpen] = useState<Omit<
    TMessageModal,
    "isVisible"
  > | null>(null);
  const dispatch = useDispatch();
  async function createOrder(): Promise<string> {
    return paymentPaypalCreate({ subscription_id: subscriptionId })
      .then((res) => {
        return res.data.transaction.reference;
      })
      .catch((err) => {
        setIsModalOpen({
          title: "PayPal",
          subTitle: err.response?.data?.message || t("errorTitle"),
          type: "error",
          btnText: t("btnClose"),
          onClose() {
            setIsModalOpen(null);
          },
        });
        return null;
      });
  }
  async function onApprove(data) {
    await paymentPaypalCheck({ reference: data.orderID })
      .then((res) => {
        dispatch(setIsCloseAllModal(true));
        setIsModalOpen({
          title: "PayPal",
          subTitle: res.data.message,
          type: "success",
          btnText: t("btnClose"),
          onClose() {
            window.location.reload();
          },
        });
      })
      .catch((err) => {
        // setTimeout(() => {
        setIsModalOpen({
          title: "PayPal",
          subTitle: err.response?.data?.message || t("errorTitle"),
          type: "error",
          btnText: t("btnClose"),
          onClose() {
            setIsModalOpen(null);
          },
        });
        // }, 3000)
      });
  }
  return (
    <>
      <PayPalScriptProvider
        options={{
          environment: "production",
          clientId: clientId,
        }}
      >
        <PayPalButtons
          style={{
            layout: "horizontal",
            tagline: false,
            color: "blue",
            label: "paypal",
            height: 36,
          }}
          createOrder={createOrder}
          onApprove={onApprove}
        />
      </PayPalScriptProvider>
      <MessageModal
        isVisible={!!isModalOpen}
        {...isModalOpen}
        handleClick={() => {
          dispatch(setIsCloseAllModal(false));
        }}
      />
    </>
  );
}
