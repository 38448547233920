import { useEffect, useState } from 'react';
import { ChevronsUp } from 'react-feather';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible ? (
        <div className="fixed bottom-4 right-4">
          <button
            onClick={scrollToTop}
            className="flex justify-center items-center w-12 h-12 rounded-full bg-secondary/40 text-secondary hover:text-teal-900 shadow-lg hover:bg-secondary/60 transition duration-300"
          >
            <ChevronsUp />
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ScrollToTopButton;
