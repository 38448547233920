import { useParams } from "react-router-dom";

import { useStaticPageDetail } from "../../services/apis/static-page.api";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../constants/commons";

export default function StaticPage() {
  let { path } = useParams();
  const { data } = useStaticPageDetail(path);
  const { i18n } = useTranslation();

  const html = (i18n.language === DEFAULT_LANGUAGE ? data?.data?.body_en : data?.data?.body) || ""
  
  return (
    <>
      <div
        className="max-w-[1440px] m-auto sm:px-8 px-4 relative py-10 flex flex-col w-full border-b"
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
      <Footer />
    </>
  );
}
