import { Dropdown } from 'antd';
import { useEffect, useState } from 'react';
import { Menu } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { path } from '../../routes/path';
import { useRootSelector } from '../../stores/reducers/root';
import Languages from '../Languages';

interface Item {
  key: string;
  showable: boolean;
  path: string;
  label: string;
}

const HeaderHome = () => {
  const { t } = useTranslation();
  const isAuthenticated = useRootSelector(
    (state) => state.auth.isAuthenticated
  );
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.scrollY > 10) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const items: Item[] = [
    {
      key: '0',
      showable: true,
      path: path.home,
      label: t('headerHome.home'),
    },
    {
      key: '1',
      showable: true,
      path: path.servicepackages,
      label: t('headerHome.servicePackage'),
    },
    // {
    //   key: '2',
    //   showable: true,
    //   path: 'hh',
    //   label: t('headerHome.sampleAlbum'),
    // },
    {
      key: '3',
      showable: !isAuthenticated,
      path: path.login,
      label: t('headerHome.login'),
    },
    {
      key: '4',
      showable: !isAuthenticated,
      path: path.register,
      label: t('headerHome.register'),
    },
    {
      key: '5',
      showable: isAuthenticated || false,
      path: path.dashboard,
      label: t('headerDashboard.dashboard'),
    },
  ];

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div
      className={`sticky top-0 z-40 w-full transition-all ${
        isVisible ? 'bg-white shadow-md' : 'bg-transparent'
      }`}
    >
      <div className="max-w-[1440px] m-auto h-14 md:h-16 sm:px-8 px-4 flex items-center justify-start md:justify-between">
        <Link to={path.home} className="text-black text-2xl font-semibold">
          Shotpik
        </Link>
        <div className="text-sm leading-6 font-semibold text-slate-700 flex space-x-8 ml-auto md:ml-0">
          {items
            .filter((i) => i.showable)
            .map((val) => {
              return (
                <NavLink
                  key={val.key}
                  to={val.path}
                  className={({ isActive }) =>
                    `${
                      isActive ? 'relative text-primary' : ''
                    } hover:text-primary transition-all md:block hidden`
                  }
                >
                  {val.label}
                </NavLink>
              );
            })}
          <div className="w-[1px] h-5 bg-slate-400 my-auto md:block hidden"></div>
          <div className="cursor-pointer text-slate-700 hover:text-primary transition-all">
            <Languages />
          </div>
        </div>
        <Dropdown
          menu={{
            items: items
              .filter((i) => i.showable)
              .map((val) => {
                return {
                  key: val.key,
                  label: <Link to={val.path}>{val.label}</Link>,
                };
              }),
          }}
          className="block md:hidden ml-3"
        >
          <div className="cursor-pointer">
            <Menu />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderHome;
