import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MessageModal from '../../components/MessageModal';
import { path } from '../../routes/path';
import { useResetPassword } from '../../services/apis/auth.api';

const ResetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();

  const [form] = Form.useForm();
  const [error, setError] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const mutation = useResetPassword();
  const onFinish = (values: { password: string; email: string }) => {
    const body = {
      email: values.email,
      password: values.password,
      token: token,
    };
    mutation.mutate(body, {
      onSuccess() {
        setIsModalOpen(true);
        form.resetFields();
      },
      onError: (error) => {
        setError(error.response?.data.status || t('formForgotPass.error'));
      },
    });
  };
  const password = Form.useWatch('password', form);

  useEffect(() => {
    if (error !== '') setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <>
      <div className="relative py-4 flex flex-col items-center sm:gap-3 gap-2 md:max-w-96 m-auto">
        <div className="text-slate-700 font-semibold text-xl sm:text-2xl">
          {t('formResetPass.title')}
        </div>
        <div className="text-slate-500 flex gap-1 text-sm">
          {t('formResetPass.subTitle')}
        </div>
        {error && <div className="text-sm text-primary mr-auto">{error}</div>}
        <Form
          form={form}
          name="reset-form"
          className="w-full flex flex-col"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredEmail')}
                  </span>
                ),
              },
              {
                type: 'email',
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.invalidatedEmail')}
                  </span>
                ),
              },
            ]}
          >
            <Input
              placeholder={t('formRegister.email')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredPassword')}
                  </span>
                ),
              },
              {
                min: 6,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.minPassword')}
                  </span>
                ),
              },
            ]}
          >
            <Input
              type="password"
              placeholder={t('formRegister.password')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('formRegister.notMatchPassword'))
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder={t('formRegister.confirmPassword')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              className="transition-all mt-4 font-medium"
              size="large"
              loading={mutation.isLoading}
              disabled={mutation.isLoading}
            >
              {t('formResetPass.changePass')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <MessageModal
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={mutation.data?.data?.status || t('formResetPass.success')}
        type="success"
        btnText={t('formRegister.linkLogin')}
        pathRedirect={path.login}
        subTitle={t('formRegister.gotoLogin')}
      />
    </>
  );
};

export default ResetPassword;
