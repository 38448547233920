import { Button, Form, Input, Modal, Skeleton } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { saveAs } from 'file-saver';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { useEffect, useState } from 'react';
import { Download, Edit3, Save, Share2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { FaRegStar, FaStar } from 'react-icons/fa';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import {
  useCommentApi,
  useLikedApi,
  useRecommendApi,
} from '../../services/apis/file.api';
import { TImage } from '../../types/album.type';
import { getLikeAnoButton, getRecommendedUserButton } from './PhotoActions';
import { useWatch } from 'antd/es/form/Form';

declare global {
  interface Window {
    pswp: any;
    passComment: string;
  }
}
const AlbumGallery = ({
  images,
  isCheckPath,
  isCommentPassword,
}: {
  images: TImage[];
  isCheckPath?: boolean;
  isCommentPassword?: boolean;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { mutate: mutateRecommend } = useRecommendApi();
  const { mutate: mutateLiked } = useLikedApi();
  const { mutate: mutateComment, isLoading: loadingComment } = useCommentApi();
  const [errorPassComment, setErrorPassComment] = useState<string>('');
  const [passComment, setPassComment] = useState<string>(null);
  const comment = useWatch('comment', form);
  const commentPass = useWatch('comment_password', form);
  const columnsCountBreakPoints = {
    default: 4,
    768: 3,
    640: 2,
  };
  const [isModalOpen, setIsModalOpen] = useState<{
    id: number;
  }>(null);
  const [loaded, setLoaded] = useState(new Array(images.length).fill(false));

  const handleImageLoad = (index: number) => {
    setLoaded((prevLoaded) => {
      const newLoaded = [...prevLoaded];
      newLoaded[index] = true;
      return newLoaded;
    });
  };

  const onFinish = (values: any) => {
    mutateComment(
      {
        id: images[isModalOpen.id].id,
        dataQuery: {
          comment: values?.comment,
          comment_password: values?.comment_password,
        },
      },
      {
        onSuccess: () => {
          document
            .querySelectorAll('#comment-' + isModalOpen.id)
            .forEach((element: any) => {
              element.innerHTML = values?.comment;
              const closestCommentWrap = (element as HTMLElement).closest(
                '.comment-wrap'
              );
              if (closestCommentWrap && !!values?.comment) {
                (closestCommentWrap as HTMLElement).style.backgroundColor =
                  '#414141';
              }
            });

          images[isModalOpen.id].comments = [
            { ...images[isModalOpen.id].comments[0], comment: values?.comment },
          ];
          if (passComment !== values.comment_password){
            setPassComment(values.comment_password);
            window.passComment = values.comment_password
          }
          setIsModalOpen(null);
        },
        onError: () => {
          setErrorPassComment(t('formConfimPassAlbum.confirmFail'));
        },
      }
    );
  };

  useEffect(() => {
    if (errorPassComment !== '') setErrorPassComment('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, commentPass]);

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: document.getElementById('gallery'),
      children: Array.from(
        document.querySelectorAll('.pswp-gallery__item')
      ).sort((a, b) => parseInt(a.id) - parseInt(b.id)) as HTMLElement[],
      showHideAnimationType: 'fade',
      arrowPrev: true,
      arrowNext: true,
      zoom: false,
      pswpModule: () => import('photoswipe'),
    });

    lightbox.on('uiRegister', function () {
      lightbox.pswp.ui.registerElement({
        name: 'recommended-button',
        className: 'pswp__recommended-button cursor-pointer',
        order: 9,
        html: '<div></div>',
        onInit: (el, pswp) => {
          pswp.on('change', () => {
            const isRecommended = images[pswp.currIndex]?.is_recommended;
            el.innerHTML = getRecommendedUserButton(
              isRecommended,
              isCheckPath,
              t('albumDetail.album.isRecommended')
            );

            el.addEventListener('click', (e) => {
              isCheckPath &&
                mutateRecommend(images[pswp.currIndex]?.id, {
                  onSuccess: () => {
                    images[pswp.currIndex].is_recommended = images[
                      pswp.currIndex
                    ]?.is_recommended
                      ? 0
                      : 1;
                    (e.target as HTMLElement).closest(
                      '.pswp__recommended-button'
                    ).innerHTML = getRecommendedUserButton(
                      images[pswp.currIndex].is_recommended,
                      isCheckPath,
                      t('albumDetail.album.isRecommended')
                    );
                  },
                });
            });

            const p = (pswp as any).currSlide.dynamicCaption
              .element as HTMLElement;

            //Hanlde download photo
            const btnDownload = p.querySelector(
              '.download'
            ) as HTMLAnchorElement;
            if (btnDownload) {
              btnDownload.onclick = (event: MouseEvent): void => {
                event.preventDefault();
                saveAs(
                  btnDownload.href,
                  btnDownload.getAttribute('data-file_name')
                );
              };
            }
          });
        },
      });
    });

    lightbox.on('change', () => {
      const pswp = lightbox.pswp;
      const p = (pswp as any).currSlide.dynamicCaption.element as HTMLElement;
      //Handle liked photo
      const btnLiked = p.querySelector('.is-liked');
      if (btnLiked) {
        btnLiked.addEventListener('click', (e) => {
          mutateLiked(images[pswp.currIndex]?.id, {
            onSuccess: () => {
              images[pswp.currIndex].is_liked = images[pswp.currIndex]?.is_liked
                ? 0
                : 1;
              (e.target as HTMLElement).closest('.is-liked').innerHTML =
                getLikeAnoButton(images[pswp.currIndex].is_liked);
            },
          });
        });
      }

      //Handle commented
      const editComment = p.querySelectorAll('.edit-comment');
      editComment &&
        editComment.forEach((item, i) => {
          item.addEventListener('click', () => {
            form.setFieldValue(
              'comment',
              images[pswp.currIndex].comments[0]?.comment || ''
            );
            form.setFieldValue('comment_password', window.passComment || '');
            console.log(window.passComment);

            setIsModalOpen({
              id: pswp.currIndex,
            });
          });
        });
    });

    new PhotoSwipeDynamicCaption(lightbox, {
      mobileLayoutBreakpoint: 700,
      type: 'below',
      mobileCaptionOverlapRatio: 1,
    });
    lightbox.init();
    return () => {
      // console.log('destroy');
      lightbox.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (!window.pswp) {
      return;
    }
    if (isModalOpen) {
      window.pswp.events.removeAll();
    } else {
      window.pswp.updateSize();
      if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
        setTimeout(() => {
          window.pswp.updateSize();
        }, 500);
      }
      window.pswp.setScrollOffset(0, window.pageYOffset);
      window.pswp.dispatch('bindEvents');
    }
  }, [isModalOpen]);

  return (
    <div id="gallery">
      <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
        <Masonry gutter="8px">
          {images.map((image, i) => {
            const elePhoto = document.getElementById(`photo${image?.id}`);
            return (
              <div key={image?.id}>
                <div key={i} className="relative">
                  <div className="pswp-gallery__item" id={i.toString()}>
                    <a
                      href={image?.url}
                      data-thumb-src={image?.url}
                      data-pswp-width={window.innerWidth}
                      data-pswp-height={
                        elePhoto
                          ? (elePhoto.clientHeight / elePhoto.clientWidth) *
                            window.innerWidth
                          : 'auto'
                      }
                      rel="noreferrer"
                      className="relative w-full"
                    >
                      {!loaded[i] && <Skeleton active />}
                      <img
                        id={`photo${image?.id}`}
                        src={image?.url}
                        className="block w-full"
                        alt="album-picture"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        onLoad={() => handleImageLoad(i)}
                      />
                    </a>
                    <div className="pswp-caption-content hidden w-full mx-4">
                      <div
                        key={i}
                        className="flex sm:flex-row flex-col gap-3 justify-center sm:justify-between items-center m-5"
                      >
                        <div
                          className={`${
                            image?.comments[0]?.comment
                              ? 'w-44 lg:w-64'
                              : 'w-full'
                          }`}
                        >
                          {image?.file_name}
                        </div>
                        <div
                          className={`comment-wrap ${
                            image?.comments[0]?.comment
                              ? 'bg-[#414141] text-yellow-50 hover:bg-[#414141]'
                              : ''
                          } flex-1 hover:boder-0 border-0 text-yellow-50 py-2 px-3 hover:text-current rounded-md flex justify-between`}
                        >
                          <div className="comment" id={`comment-${i}`}>
                            {image?.comments[0]?.comment}
                          </div>
                        </div>
                        <div
                          className={`justify-self-end flex gap-2 items-center justify-end ${
                            image?.comments[0]?.comment
                              ? 'w-44 lg:w-64'
                              : 'w-full'
                          }`}
                        >
                          {!isCheckPath && isCommentPassword && (
                            <div className="w-10 h-10 flex gap-1 justify-center items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-full edit-comment">
                              <Edit3 className="w-5" />
                            </div>
                          )}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getLikeAnoButton(
                                image?.is_liked,
                                isCheckPath
                              ),
                            }}
                          />
                          <a
                            href={image?.url}
                            target="_blank"
                            data-file_name={image.file_name}
                            rel="noreferrer"
                            className="w-10 h-10 flex gap-1 justify-center items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-full download"
                          >
                            <Download className="w-5" />
                          </a>
                          <div className="w-10 h-10 flex gap-1 justify-center items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-full share">
                            <Share2 className="w-5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loaded[i] && (
                    <>
                      <div
                        className={`p-2 absolute top-0 left-0 ${
                          isCheckPath ? 'cursor-pointer' : 'cursor-default'
                        } ${
                          !isCheckPath && !image?.is_recommended ? 'hidden' : ''
                        }`}
                        onClick={() => {
                          if (isCheckPath)
                            mutateRecommend(image.id, {
                              onSuccess: () => {
                                image.is_recommended = image.is_recommended
                                  ? 0
                                  : 1;
                              },
                            });
                        }}
                      >
                        {!!image?.is_recommended ? (
                          <FaStar className="text-secondary text-xl opacity-90" />
                        ) : (
                          <FaRegStar className="text-secondary text-xl opacity-90" />
                        )}
                      </div>
                      <div
                        className={`p-2 absolute top-0 right-0 ${
                          isCheckPath && !image?.is_liked ? 'hidden' : ''
                        }`}
                        onClick={() => {
                          if (!isCheckPath) {
                            mutateLiked(image.id, {
                              onSuccess: () => {
                                image.is_liked = image.is_liked ? 0 : 1;
                              },
                            });
                          }
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getLikeAnoButton(
                              image?.is_liked,
                              isCheckPath
                            ),
                          }}
                        ></div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t('albumDetail.album.titleComment')}
          </div>
        }
        open={!!isModalOpen}
        onCancel={() => {
          setIsModalOpen(null);
          form.resetFields();
        }}
        footer={null}
        className="photo-gallery"
      >
        {errorPassComment && (
          <div className="text-sm text-primary mr-auto">{errorPassComment}</div>
        )}
        <div className="flex flex-col gap-5">
          <Form
            form={form}
            name="comment-photo-form"
            layout="vertical"
            onFinish={onFinish}
            colon={false}
          >
            <Form.Item
              name="comment"
              label={t('albumDetail.album.labelComment')}
            >
              <TextArea
                autoSize
                autoFocus
                onFocus={(e) => {
                  e.target.focus();
                }}
                maxLength={400}
              />
            </Form.Item>
            <Form.Item
              name="comment_password"
              className={`mb-0 relative ${passComment ? 'hidden' : ''}`}
              label={t('albumDetail.album.commentPassword')}
              rules={[
                {
                  required: true,
                  message: (
                    <span className="text-xs absolute">
                      {t('formCreateAlbum.requirePass')}
                    </span>
                  ),
                },
                {
                  min: 6,
                  message: (
                    <span className="text-xs mb-1 block">
                      {t('formLogin.minPassword')}
                    </span>
                  ),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={`submit-comment success transition-all flex gap-2 items-center w-fit ml-auto mt-4`}
              disabled={loadingComment}
              loading={loadingComment}
              onClick={() => {}}
            >
              <Save className="w-4" />
              {t('albumDetail.album.saveComment')}
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AlbumGallery;
