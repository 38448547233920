import { configureStore } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { TAuthStore } from '../../types/auth.type';
import authReducer from './auth.reducer';
import userReducer from './user.reducer';
import { TUserStore } from '../../types/user.type';
import displayModeReducer from './displayMode.reducer';
import { TDisplayMode, TSortSearch } from '../../types/common.type';
import sortSearchReducer from './sortSearch.reducer';
import albumReducer from './album.reducer';
import { TAlbumStore } from '../../types/album.type';
import { TPaymentStore } from '../../types/membership.type';
import paymentReducer from './payment.reducer';

export interface IRootState {
  auth: TAuthStore;
  user: TUserStore;
  displayMode: TDisplayMode;
  sortSearch: TSortSearch;
  album: TAlbumStore;
  payment: TPaymentStore;
}

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    displayMode: displayModeReducer,
    sortSearch: sortSearchReducer,
    album: albumReducer,
    payment: paymentReducer,
  },
});

export const useRootSelector = <T>(
  selector: (state: IRootState) => T,
  defaultValue?: T
): T | undefined => {
  try {
    const value = useSelector(selector);
    return value || defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

export default store;
