import { Input } from 'antd';
import { toPng } from 'html-to-image';
import { useContext, useRef } from 'react';
import { Clipboard, Download } from 'react-feather';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useRootSelector } from '../../stores/reducers/root';
import { AbilityContext, PERMISSION } from '../../services/ability.service';

const ShareAlbum = ({ albumId }: { albumId: string }) => {
  const { t } = useTranslation();
  const ab = useContext(AbilityContext);
  const user = useRootSelector((state) => state.user.userProfile);
  const linkShare =
    (user?.setting?.sub_domain_name && ab.can('can', PERMISSION.PRIVATE_DOMAIN)
      ? 'https://' + user?.setting?.sub_domain_name
      : window.location.origin) +
    '/albums/' +
    albumId;
  const qrCodeRef = useRef();
  const downloadQRCode = () => {
    if (qrCodeRef.current === null) {
      return;
    }
    toPng(qrCodeRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qr-code.png';
        link.click();
      })
      .catch(() => {});
  };

  return (
    <>
      <div>{t('dashboard.albums.action.download.modalShare.linkShare')}</div>
      <div className="flex gap-3 items-center">
        <Input
          className="flex-1 !cursor-default !text-gray-600 border-0 py-2 px-3 !bg-gray-100"
          value={linkShare}
          disabled
        />
        <div
          onClick={() => {
            navigator.clipboard.writeText(linkShare).then(() => {});
          }}
          className="text-secondary flex gap-2 items-center cursor-pointer py-1 px-3 rounded-2xl hover:bg-[#0bcdac26] transition-all"
        >
          <Clipboard className="w-4" />
          {t('dashboard.albums.action.download.modalShare.btnCopy')}
        </div>
      </div>
      <div className="flex gap-6 justify-center mt-6 items-center">
        <div ref={qrCodeRef}>
          <QRCode size={80} value={linkShare} viewBox={`0 0 80 80`} />
        </div>
        <div onClick={downloadQRCode} className="flex flex-col">
          {t('dashboard.albums.action.download.modalShare.qrShare')}
          <div className="text-secondary flex gap-2 items-center cursor-pointer py-1 px-3 -ml-3 rounded-2xl hover:bg-[#0bcdac26] transition-all">
            <Download className="w-4" />
            {t('dashboard.albums.action.download.modalShare.btndownload')}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareAlbum;
