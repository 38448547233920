import { Button, Checkbox, Form, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { Download } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useGetAlbumApi } from '../../services/apis/album.api';
import ZipDownloader from '../../services/zip.service';

const DOWNLOAD_ALBUM = {
  OPTION: {
    ALL: 'all',
    RECOMMEND: 'recommend',
    LIKE: 'like',
  } as const,
  FORMAT_FILE: {
    DEFAULT: 'default',
    TXT: 'txt',
  } as const,
  INSERT_COMMENT: {
    YES: '1',
    NO: '0',
  } as const,
};

type OptionType =
  (typeof DOWNLOAD_ALBUM.OPTION)[keyof typeof DOWNLOAD_ALBUM.OPTION];
type FormatFileType =
  (typeof DOWNLOAD_ALBUM.FORMAT_FILE)[keyof typeof DOWNLOAD_ALBUM.FORMAT_FILE][];
type InsertCommentType =
  (typeof DOWNLOAD_ALBUM.INSERT_COMMENT)[keyof typeof DOWNLOAD_ALBUM.INSERT_COMMENT];

interface FormData {
  optionDownload: OptionType;
  formatDownload: FormatFileType;
  commentDownload: InsertCommentType;
}

const DownloadAlbum = ({
  id,
  totalPhotos,
  onClick,
}: {
  id: string;
  totalPhotos: number;
  onClick?: () => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: albumDetail,
    refetch: refetchAlbum,
    isLoading: loadingAlbum,
    isFetching,
  } = useGetAlbumApi({
    perPage: totalPhotos,
    albumIdProps: id,
  });
  let images = albumDetail?.pages[0]?.data?.data?.files?.data;

  const formatOptions = [
    {
      label: t(
        'dashboard.albums.action.download.modalDownload.formatDownload.value1'
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE.DEFAULT,
    },
    {
      label: t(
        'dashboard.albums.action.download.modalDownload.formatDownload.value2'
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE.TXT,
    },
  ];

  useEffect(() => {
    refetchAlbum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: FormData) => {
    setIsLoading(true);
    const zip = new ZipDownloader();
    if (values.optionDownload === 'recommend') {
      images = images.filter((image) => image.is_recommended);
    } else if (values.optionDownload === 'like') {
      images = images.filter((image) => image.is_liked);
    }

    if (values.formatDownload.includes('default')) {
      await zip.addMulti(images.map((image) => image.url));
    }
    if (values.formatDownload.includes('txt')) {
      zip.file('list.txt', images.map((image) => image.file_name).join('\n'));
    }
    if (values.commentDownload === '1') {
      const imagesCmt = images.filter((image) => image.comments_count);
      zip.file(
        'comments.txt',
        imagesCmt
          .map(
            (image) =>
              '- File:' +
              image.file_name +
              ':\n' +
              image.comments.map((c) => c.comment).join('	  ')
          )
          .join('\n')
      );
    }

    zip.save(albumDetail?.pages[0]?.data?.data?.name);
    onClick && onClick();
    form.resetFields();
    setIsLoading(false);
  };

  return (
    <Form form={form} onFinish={onFinish} colon={false} layout="vertical">
      <div className="flex justify-between flex-col sm:flex-row">
        <Form.Item
          label={t(
            'dashboard.albums.action.download.modalDownload.optionDownload.title'
          )}
          name="optionDownload"
          initialValue={DOWNLOAD_ALBUM.OPTION.ALL}
        >
          <Radio.Group name="optionDownload" className="flex flex-col gap-1">
            <Radio value={DOWNLOAD_ALBUM.OPTION.ALL}>
              {t(
                'dashboard.albums.action.download.modalDownload.optionDownload.value1'
              )}
            </Radio>
            <Radio value={DOWNLOAD_ALBUM.OPTION.LIKE}>
              {t(
                'dashboard.albums.action.download.modalDownload.optionDownload.value2'
              )}
            </Radio>
            <Radio value={DOWNLOAD_ALBUM.OPTION.RECOMMEND}>
              {t(
                'dashboard.albums.action.download.modalDownload.optionDownload.value3'
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <div className="flex flex-col">
          <Form.Item
            label={t(
              'dashboard.albums.action.download.modalDownload.formatDownload.title'
            )}
            name="formatDownload"
            initialValue={[DOWNLOAD_ALBUM.FORMAT_FILE.DEFAULT]}
            rules={[
              () => ({
                validator(_, value: string[]) {
                  if (!value.length) {
                    return Promise.reject(
                      new Error(
                        t(
                          'dashboard.albums.action.download.modalDownload.error_least_1'
                        )
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Checkbox.Group
              name="formatDownload"
              className="flex flex-col gap-1"
              options={formatOptions}
            ></Checkbox.Group>
          </Form.Item>
          <Form.Item
            label={t(
              'dashboard.albums.action.download.modalDownload.commentDownload.title'
            )}
            name="commentDownload"
            initialValue={DOWNLOAD_ALBUM.INSERT_COMMENT.NO}
          >
            <Radio.Group name="commentDownload" className="flex flex-col gap-1">
              <Radio value={DOWNLOAD_ALBUM.INSERT_COMMENT.YES}>
                {t(
                  'dashboard.albums.action.download.modalDownload.commentDownload.value1'
                )}
              </Radio>
              <Radio value={DOWNLOAD_ALBUM.INSERT_COMMENT.NO}>
                {t(
                  'dashboard.albums.action.download.modalDownload.commentDownload.value2'
                )}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <Form.Item className="mb-0 flex justify-center items-center">
        <Button
          type="default"
          htmlType="submit"
          className={`transition-all success ${
            isFetching || loadingAlbum ? 'opacity-70' : ''
          }`}
          disabled={isLoading || loadingAlbum || isFetching}
          loading={isLoading}
        >
          <Download className="w-4" />
          {t('dashboard.albums.action.download.modalDownload.btn')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DownloadAlbum;
