import { GoogleOAuthProvider } from '@react-oauth/google';
import { Button, Form, Input } from 'antd';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MessageModal from '../../components/MessageModal';
import { path } from '../../routes/path';
import { useRegisterApi } from '../../services/apis/auth.api';
import { TRegisterForm } from '../../types/form.type';
import GoogleLogin from '../Login/google';
import ReCAPTCHA from "react-google-recaptcha";


const clientId = process.env.REACT_APP_CLIENT_ID_GOOGLE as string;

const Register = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [error, setError] = useState<string>('');
  const recaptchaRef = createRef<any>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const mutation = useRegisterApi();
  const onFinish = (values: TRegisterForm) => {
    mutation.mutate(values, {
      onSuccess() {
        setIsModalOpen(true);
        form.resetFields();
      },
      onError: () => {
        setError(t('formRegister.registerFail'));
      },
    });

    recaptchaRef.current.reset();
    form.setFieldValue("recaptcha_token", "");
  };
  const email = Form.useWatch('email', form);
  const password = Form.useWatch('password', form);
  const name = Form.useWatch('name', form);
  const website = Form.useWatch('website', form);

  const recaptcha_token = Form.useWatch("recaptcha_token", form);

  const handleRecaptcha = (value) => {
    form.setFieldValue("recaptcha_token", value);
  };

  useEffect(() => {
    if (error !== '') setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, name, website]);

  return (
    <>
      <div className="relative py-4 flex flex-col items-center sm:gap-3 gap-2 md:max-w-96 m-auto">
        <div className="text-slate-700 font-semibold text-xl sm:text-2xl">
          {t('formRegister.title')}
        </div>
        <div className="text-slate-500 flex gap-1 text-sm">
          {t('formRegister.subTitle')}
          <Link to={path.login} className="text-primary hover:text-red-500/85">
            {t('formRegister.linkLogin')}
          </Link>
        </div>
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin setError={setError} />
        </GoogleOAuthProvider>
        <div className="relative w-full text-slate-500 my-5 text-sm before:w-[30%] before:absolute before:-translate-y-1/2 before:top-1/2 before:left-0 before:h-[1px] before:bg-slate-900/30 after:w-[30%] after:absolute after:-translate-y-1/2 after:top-1/2 after:right-0 after:h-[1px] after:bg-slate-900/30">
          <span className="bg-transparent text-center w-full block">
            {t('formRegister.orUse')}
          </span>
        </div>
        {error && <div className="text-sm text-primary mr-auto">{error}</div>}
        <Form
          form={form}
          name="register-form"
          className="w-full flex flex-col"
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredName')}
                  </span>
                ),
              },
            ]}
          >
            <Input
              autoFocus
              placeholder={t('formRegister.name')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item name="website">
            <Input
              placeholder={t('formRegister.website')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredEmail')}
                  </span>
                ),
              },
              {
                type: 'email',
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.invalidatedEmail')}
                  </span>
                ),
              },
            ]}
          >
            <Input
              placeholder={t('formRegister.email')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.requiredPassword')}
                  </span>
                ),
              },
              {
                min: 6,
                message: (
                  <span className="text-xs mb-1 block">
                    {t('formRegister.minPassword')}
                  </span>
                ),
              },
            ]}
          >
            <Input
              type="password"
              placeholder={t('formRegister.password')}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t('formRegister.notMatchPassword'))
                  );
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder={t('formRegister.confirmPassword')}
              className="py-2 px-3"
            />
          </Form.Item>
          <ReCAPTCHA
            className="flex justify-center items-center"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleRecaptcha}
          />
          <Form.Item name="recaptcha_token" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              className="transition-all mt-4 font-medium"
              size="large"
              loading={mutation.isLoading}
              disabled={mutation.isLoading || !recaptcha_token || recaptcha_token === ''}
            >
              {t('formRegister.btnRegister')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <MessageModal
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('formRegister.registerSuccess')}
        type="success"
        btnText={t('formRegister.linkLogin')}
        pathRedirect={path.login}
        subTitle={t('formRegister.gotoLogin')}
      />
    </>
  );
};

export default Register;
