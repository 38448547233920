import { Button, Form, Input } from 'antd';
import { t } from 'i18next';
import { createRef, useState } from 'react';
import { useForgotPassword } from '../../services/apis/auth.api';
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const recaptchaRef = createRef<any>();
  const mutation = useForgotPassword();

  const onFinish = (values: { email: string }) => {
    mutation.mutate(values, {
      onSuccess: (res) => {
        setSuccess(res.data.status);
        setError('');
      },
      onError: (error) => {
        setError(error.response?.data.status || t('formForgotPass.error'));
        setSuccess('');
      },
    });

    recaptchaRef.current.reset();
    form.setFieldValue("recaptcha_token", "");
  };

  const recaptcha_token = Form.useWatch("recaptcha_token", form);

  const handleRecaptcha = (value) => {
    form.setFieldValue("recaptcha_token", value);
  };

  return (
    <>
      <div className="relative py-4 flex flex-col sm:gap-3 gap-2 md:max-w-96 m-auto">
        <div className="text-slate-700 font-semibold text-xl sm:text-2xl text-center">
          {t('formForgotPass.title')}
        </div>
        <div className="text-slate-500 text-sm">
          {t('formForgotPass.description')}
        </div>
        {error && (
          <div className="text-sm text-primary font-extralight">{error}</div>
        )}

        {success && (
          <div className="text-sm text-green-600 font-extralight">
            {success}
          </div>
        )}

        <Form
          form={form}
          name="reset-form"
          className="reset-form w-full flex flex-col"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs">
                    {t('formForgotPass.requiredEmail')}
                  </span>
                ),
              },
              {
                type: 'email',
                message: (
                  <span className="text-xs">
                    {t('formForgotPass.invalidatedEmail')}
                  </span>
                ),
              },
            ]}
          >
            <Input placeholder="Email" className="py-2 px-3" />
          </Form.Item>

          <ReCAPTCHA
            className="flex justify-center items-center"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleRecaptcha}
          />
          <Form.Item name="recaptcha_token" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              className="transition-all mt-4 font-medium"
              size="large"
              loading={mutation.isLoading}
              disabled={mutation.isLoading || !recaptcha_token || recaptcha_token === ''}
            >
              {t('formForgotPass.sentMail')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ForgotPassword;
