import { createSlice } from '@reduxjs/toolkit';
import { getDisplayViewFromLS } from '../../utils/localStorage';
import { TDisplayMode } from '../../types/common.type';

const initialState: TDisplayMode = {
  mode: getDisplayViewFromLS(),
};

const displayModeSlice = createSlice({
  name: 'displayModeSlice',
  initialState: initialState,
  reducers: {
    setDisplayMode: (state, action) => {
      return {
        ...state,
        mode: action.payload,
      };
    },
  },
});

export const { setDisplayMode } = displayModeSlice.actions;

export default displayModeSlice.reducer;
