import { Input, Popover, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { AlignRight, Grid, Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DISPLAY_VIEW, SORT_MODE } from '../../constants/commons';
import { useDebounce } from '../../hook/useDebounce';
import { setDisplayMode } from '../../stores/reducers/displayMode.reducer';
import { useRootSelector } from '../../stores/reducers/root';
import {
  setSortMode,
  setValueSearch,
} from '../../stores/reducers/sortSearch.reducer';
import { setDisplayViewToLS } from '../../utils/localStorage';
import { PERMISSION } from '../../services/ability.service';

type TPropsNavMenu = {
  totalAlbums?: number;
};

export const NavMenu = ({ totalAlbums }: TPropsNavMenu) => {
  const { t } = useTranslation();
  const dispath = useDispatch();
  const displayMode = useRootSelector((state) => state.displayMode.mode);
  const user = useRootSelector((state) => state.user.userProfile);
  const sortMode = useRootSelector((state) => state.sortSearch.mode);
  const [value, setValue] = useState<string>('');
  const debounce = useDebounce<string>({ value, delay: 500 });
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const onChangeDisplayMode = (e: any) => {
    dispath(setDisplayMode(e.target.value));
    setDisplayViewToLS(e.target.value);
  };
  const onChangeSortMode = (e: any) => {
    dispath(setSortMode(e.target.value));
  };

  let numberLimit: any =
    user.membership_plan.plan_permissions?.find(
      (per) => per.name === PERMISSION.NUM_ALBUM
    ).number || 0;

  numberLimit = numberLimit > 999 ? '∞' : numberLimit;

  useEffect(() => {
    dispath(setValueSearch(debounce));
  }, [debounce, dispath]);

  const contentView = (
    <Radio.Group
      name="displayView"
      defaultValue={displayMode}
      className="flex flex-col gap-2"
      onChange={onChangeDisplayMode}
    >
      <Radio value={DISPLAY_VIEW[0]}>
        {t('dashboard.control.display.value1')}
      </Radio>
      <Radio value={DISPLAY_VIEW[1]}>
        {t('dashboard.control.display.value2')}
      </Radio>
    </Radio.Group>
  );

  const contentSort = (
    <Radio.Group
      name="sortAlbums"
      defaultValue={sortMode}
      className="flex flex-col gap-2"
      onChange={onChangeSortMode}
    >
      <Radio value={SORT_MODE[0]}>
        {t('dashboard.control.arrange.value1')}
      </Radio>
      <Radio value={SORT_MODE[1]}>
        {t('dashboard.control.arrange.value2')}
      </Radio>
    </Radio.Group>
  );

  return (
    <div className="flex sm:flex-row flex-col flex-wrap">
      <div className="flex sm:space-x-3 text-sm items-center flex-wrap sm:flex-nowrap">
        <Popover content={contentSort} trigger="click">
          <div className="py-1 px-3 flex gap-1 items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-3xl">
            <AlignRight className="w-4" />
            {t('dashboard.control.arrange.title')}
          </div>
        </Popover>
        <Popover content={contentView} trigger="click">
          <div className="py-1 px-3 flex gap-1 items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-3xl">
            <Grid className="w-4" /> {t('dashboard.control.display.title')}
          </div>
        </Popover>
        <div className="py-1 px-3 flex gap-1 cursor-pointer transition-all hover:bg-gray-200/40 rounded-3xl">
          {t('dashboard.control.albumNumber')}:
          <div className="text-black font-medium">{totalAlbums || 0}</div>
        </div>
        <div className="py-1 px-3 flex gap-1 cursor-pointer transition-all hover:bg-gray-200/40 rounded-3xl">
          {t('dashboard.control.albumNumberPerMonth')}:
          <div className="text-black font-medium">
            {user.album_in_month}/{numberLimit}
          </div>
        </div>
      </div>
      <Input
        placeholder={t('dashboard.control.placeholderSearch')}
        className="w-full md:w-48 lg:w-64 md:ml-auto py-1 px-3 md:mt-0 mt-1"
        suffix={<Search className="w-4" />}
        onChange={handleOnChange}
      />
    </div>
  );
};
