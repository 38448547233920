import { useEffect, useState } from 'react';

type TDebounce<T> = {
  value: T;
  delay: number;
};

export const useDebounce = <T>({ value, delay }: TDebounce<T>) => {
  const [debounce, setDebounce] = useState<T>(value);
  useEffect(() => {
    const timmer = setTimeout(() => setDebounce(value), delay);
    return () => clearTimeout(timmer);
  }, [delay, value]);
  return debounce;
};
