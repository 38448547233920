import { Select, Space } from "antd";
import { TFolderAlbum } from "../../types/album.type";
import { useTranslation } from "react-i18next";

const FolderAlbum = ({
  folders,
  onClick,
}: {
  folders: TFolderAlbum[];
  onClick: (values: number[]) => void;
}) => {
  const { t } = useTranslation();
  const optionFilter = folders?.map((item) => ({
    value: item?.id,
    label: item?.folder_name,
  }));
  const handleChange = (values: number[]) => {
    onClick && onClick([...values]);
  };

  return (
    <Select
      mode="multiple"
      style={{
        width: "100%",
      }}
      placeholder={t("albumDetail.folders.title")}
      onChange={handleChange}
      options={optionFilter}
      optionRender={(option) => <Space>{option.data.label}</Space>}
    />
  );
};

export default FolderAlbum;
