import { Radio } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TMembershipPlanPrice,
  TMembershipPlansResponse,
} from "../../types/membership.type";
import { clusterPackage } from "../../utils/common";
import { getPackageFromIdPrice, useCheckUnit } from "../../utils/membership";

const ListPackage = ({
  listPlanPrice,
  calculateUpgrade,
  membershipPlans,
  keyRefetch,
  onClick,
}: {
  listPlanPrice: TMembershipPlanPrice[];
  calculateUpgrade?: any;
  membershipPlans: TMembershipPlansResponse;
  keyRefetch?: number;
  onClick: (data: {
    membershipPlanPriceId: number;
    price: number;
    duration?: number;
  }) => void;
}) => {
  const { t } = useTranslation();
  const checkUnit = useCheckUnit();
  const [value, setValue] = useState<number>(undefined);
  const listPackage = clusterPackage(listPlanPrice);
  const handleChange = (e) => {
    setValue(e.target?.value);
    onClick &&
      onClick({
        membershipPlanPriceId: e.target?.value,
        price: listPlanPrice?.find((item) => item?.id === e.target?.value)?.[
          checkUnit
        ],
        duration: listPlanPrice?.find((item) => item?.id === e.target?.value)
          ?.duration,
      });
  };

  useEffect(() => {
    setValue(listPlanPrice[0]?.id);
    onClick &&
      onClick({
        membershipPlanPriceId: listPlanPrice[0]?.id,
        price: listPlanPrice[0]?.[checkUnit],
        duration: listPlanPrice[0]?.duration,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipPlans, keyRefetch, checkUnit, calculateUpgrade]);

  return (
    <Radio.Group
      name={`membership_plan_id${listPlanPrice?.length}`}
      className="flex flex-col w-fit mb-4"
      onChange={handleChange}
      value={value}
    >
      {Object.entries(listPackage).map(([key, planPrice]) => (
        <div className="flex flex-col w-fit" key={key}>
          {listPlanPrice?.length !== 1 && (
            <Radio className="cursor-default text-black -ml-2" disabled>
              {t(
                `servicePackages.payment.package${
                  getPackageFromIdPrice(+key, membershipPlans)?.name
                }`
              )}
            </Radio>
          )}
          {planPrice?.map((item) => (
            <Radio
              value={item?.id}
              key={item?.id}
              disabled={listPlanPrice?.length === 1}
              className={
                listPlanPrice?.length === 1
                  ? "cursor-default text-black -ml-2"
                  : ""
              }
            >
              {getPackageFromIdPrice(item?.membership_plan_id, membershipPlans)
                ?.name +
                " " +
                (item?.duration % 12 === 0
                  ? item?.duration / 12
                  : item?.duration) +
                " " +
                t(
                  `servicePackages.payment.${
                    item?.duration % 12 === 0
                      ? "packageYearUnit"
                      : "packageMonthUnit"
                  }`
                )}
              <span className="text-primary text-lg ml-2">
                {item?.[checkUnit]?.toLocaleString("de-DE")}
              </span>
              <span className="text-xs">
                {t("servicePackages.payment.priceUnit")}
              </span>
            </Radio>
          ))}
        </div>
      ))}
    </Radio.Group>
  );
};

export default ListPackage;
