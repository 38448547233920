import axios, { AxiosError, type AxiosInstance } from 'axios';
import { TOKEN_EXPIRED, VALID_TOKEN } from '../constants/commons';
import { localStorageKeys } from '../constants/localStorageKeys';
import { setIsAuthenticated } from '../stores/reducers/auth.reducer';
import store from '../stores/reducers/root';
import { TValidToken } from '../types/auth.type';
import { getLanguageFromLS } from '../utils/localStorage';
import QueryString from 'qs';

const apiUrl = process.env.REACT_APP_API_URL;

export class Http {
  instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        'expire-access-token': 60 * 60 * 24, // 1 ngày
      },
      paramsSerializer: params => {
        return QueryString.stringify(params, { arrayFormat: 'brackets' });
      },
    });

    this.instance.interceptors.request.use(
      (config) => {
        if (config.headers) {
          config.headers.Authorization =
            'Bearer ' + localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
        }
        if (getLanguageFromLS()) {
          config.headers['x-lang'] = getLanguageFromLS();
        }
        if (!!store.getState().auth.passAlbum) {
          config.headers['x-password'] = store.getState().auth.passAlbum;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.instance.interceptors.response.use(
      async (response) => {
        return response;
      },
      (error: AxiosError<TValidToken>) => {
        const errorValidToken = error?.response?.data?.message;
        if (
          (errorValidToken === VALID_TOKEN ||
            errorValidToken === TOKEN_EXPIRED) &&
          error?.response?.status === 401
        ) {
          store.dispatch(setIsAuthenticated(false));
        }
        return Promise.reject(error);
      }
    );
  }
}
const http = new Http().instance;

export default http;
