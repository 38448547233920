import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../constants/commons";
import {
  TMembershipPlansResponse,
  TNamePackage,
} from "../types/membership.type";

export const getPackageFromIdPrice = (
  membership_plan_id: number,
  membershipPlans: TMembershipPlansResponse
) => {
  return membershipPlans?.find((item) => item?.id === membership_plan_id);
};

export const useCheckUnit = () => {
  const { i18n } = useTranslation();
  return i18n.language === DEFAULT_LANGUAGE ? "price_usd" : "price";
};

export const useMembershipPlansPrice = (
  name: TNamePackage,
  membershipPlans: TMembershipPlansResponse
) => {
  return membershipPlans?.find((items) => items?.name === name)
    ?.membership_plan_price;
};
