import { Skeleton, Spin } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo } from 'react';
import { Heart, MessageSquare, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionAlbum from '../../components/Albums/ActionAlbum';
import CreateAlbum from '../../components/Albums/CreateAlbum';
import ScrollToTopButton from '../../components/ScrollToTopButton.tsx';
import { DISPLAY_VIEW } from '../../constants/commons';
import { AbilityContext, PERMISSION } from '../../services/ability.service';
import { useGetAlbumsApi } from '../../services/apis/album.api';
import { setIsOpenCreateAlbum } from '../../stores/reducers/album.reducer';
import { useRootSelector } from '../../stores/reducers/root';
import { NavMenu } from './NavMenu';
import { TAlbum } from '../../types/album.type';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const displayMode = useRootSelector((state) => state.displayMode.mode);
  const sortMode = useRootSelector((state) => state.sortSearch.mode);
  const valueSearch = useRootSelector((state) => state.sortSearch.valueSearch);
  const isGrid = displayMode === DISPLAY_VIEW[0];
  const ability = useContext(AbilityContext);
  const isCreateAlbum = ability.can('can', PERMISSION.CREATE_ALBUM);
  const { ref, inView } = useInView();
  const {
    data: albumsData,
    isLoading: loadingAlbums,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAlbumsApi({ sortMode, valueSearch });
  const albumsResponse: TAlbum[] = useMemo(() => {
    return albumsData?.pages.reduce((acc, page) => {
      return [...acc, ...page?.data?.data];
    }, []);
  }, [albumsData]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <>
      <div className="flex flex-col gap-4 text-gray-900 py-4">
        <NavMenu totalAlbums={albumsData?.pages[0]?.data?.meta?.total} />
        <div
          className={`grid ${
            isGrid
              ? 'xl:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-3 gap-2'
              : 'grid-cols-1 gap-3'
          }`}
        >
          {loadingAlbums ? (
            <Skeleton active className="py-4" />
          ) : (
            <>
              <button
                onClick={() => {
                  dispatch(setIsOpenCreateAlbum(true));
                }}
                disabled={!isCreateAlbum}
                className={`relative border transition-all border-dashed rounded-xl bg-[#f6f8fa] ${
                  !isCreateAlbum
                    ? 'cursor-not-allowed opacity-60 border-primary'
                    : 'border-secondary hover:bg-gray-200/60'
                }`}
              >
                <div
                  className={`absolute w-full h-full flex gap-1 items-center justify-center ${
                    isGrid ? 'flex-col' : 'flex-row h-32'
                  }`}
                >
                  <Plus />
                  <div>{t('dashboard.btnCreate')}</div>
                </div>
                <div className={`${isGrid ? 'pb-[100%]' : ''}`}></div>
              </button>
              {albumsResponse &&
                albumsResponse?.map((album) => (
                  <div
                    onClick={() => {
                      navigate(`/dashboard/albums/${album.id}`);
                    }}
                    key={album?.id}
                    className={`${
                      isGrid ? '' : 'h-32'
                    } relative w-full rounded-xl cursor-pointer flex`}
                  >
                    <img
                      src={album?.thumbnail_url}
                      className={`${
                        isGrid
                          ? 'absolute top-0 left-0 w-full h-full rounded-xl'
                          : 'w-1/3 rounded-l-xl'
                      } object-cover`}
                      alt=""
                    />
                    <div className={`${isGrid ? 'pb-[100%]' : ''}`}></div>
                    <div
                      className={`${
                        isGrid
                          ? 'absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex-col justify-end rounded-xl p-3'
                          : 'flex-row bg-gray-400 flex-1 rounded-r-xl justify-between items-center p-5'
                      } flex text-white`}
                    >
                      <div className="font-semibold flex flex-col">
                        <span className="block text-sm sm:text-lg line-clamp-2">
                          {album?.name}
                        </span>
                        <div className="text-xs text-gray-50">
                          {t('dashboard.createdDate')}:{' '}
                          {dayjs(album?.created_at).format('DD-MM-YYYY')}
                        </div>
                      </div>
                      {!isGrid ? (
                        <div className="flex text-xs space-x-2 mt-2 text-gray-50">
                          <div className="flex gap-1 items-center">
                            <Heart className="w-3" />
                            25634
                          </div>
                          <div className="flex gap-1 items-center">
                            <MessageSquare className="w-3" />
                            25634
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="flex items-center justify-between text-gray-50">
                        {isGrid ? (
                          <div className="flex text-xs space-x-2">
                            <div className="flex gap-1 items-center">
                              <Heart className="w-3" />
                              {album?.like_count}
                            </div>
                            <div className="flex gap-1 items-center">
                              <MessageSquare className="w-3" />
                              {album?.comment_count}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <ActionAlbum
                          albumId={album?.id}
                          isDownloadable={album?.is_downloadable}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
          <div ref={ref}>
            {isFetchingNextPage ? (
              <div className="py-6 w-full flex justify-center fixed bottom-0 left-1/2 -translate-x-1/2 bg-gradient-to-t from-white to-transparent">
                <Spin size="large" />
              </div>
            ) : (
              <></>
            )}
          </div>
          <CreateAlbum />
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default Dashboard;
