import { Dropdown, Modal } from 'antd';
import { useState } from 'react';
import { Download, Edit, MoreHorizontal, Share2, Trash } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useDeleteAlbumApi,
  useGetAlbumApi,
} from '../../services/apis/album.api';
import { setIsDeleteSuccess } from '../../stores/reducers/album.reducer';
import { useRootSelector } from '../../stores/reducers/root';
import MessageModal, { TTypeMessage } from '../MessageModal';
import DownloadAlbum from './DownloadAlbum';
import EditAlbum from './EditAlbum';
import ShareAlbum from './ShareAlbum';

type TModalOpen = {
  delete?: {
    type: TTypeMessage;
    title: string;
    btn: string;
    handleClick?: () => void;
  };
  download?: {};
  edit?: boolean;
};

const ActionAlbum = ({
  albumId,
  isDownloadable,
  filesCount,
}: {
  albumId: string;
  isDownloadable?: boolean;
  filesCount?: number;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpenShare, setIsModalOpenShare] = useState<boolean>(false);
  const isDeleteSuccess = useRootSelector(
    (state) => state.album.isDeleteSuccess
  );
  const {
    data: albumDetail,
    refetch: refetchAlbum,
    isLoading,
  } = useGetAlbumApi({
    albumIdProps: albumId,
    enabled: false,
  });
  const album = albumDetail?.pages[0]?.data?.data;
  const [modalOpen, setModalOpen] = useState<TModalOpen | undefined>(undefined);
  const deleteAlbum = useDeleteAlbumApi();
  const handleDetele = () => {
    deleteAlbum.mutate(albumId, {
      onSuccess: () => {
        setModalOpen({
          delete: {
            type: 'success',
            title: t('dashboard.albums.action.delete.success'),
            btn: t('dashboard.albums.action.delete.btn'),
            handleClick: null,
          },
        });
      },
      onError: () => {
        setModalOpen({
          delete: {
            type: 'error',
            title: t('dashboard.albums.action.delete.failed'),
            btn: t('dashboard.albums.action.delete.btn'),
            handleClick: null,
          },
        });
      },
    });
  };
  const items = [
    {
      key: 'edit',
      label: <div>{t('dashboard.albums.action.edit')}</div>,
      disabled: isLoading,
      icon: <Edit className="w-4" />,
    },
    {
      key: 'share',
      label: <div>{t('dashboard.albums.action.share')}</div>,
      icon: <Share2 className="w-4" />,
    },
    {
      key: 'download',
      label: <div>{t('dashboard.albums.action.download.title')}</div>,
      disabled: !isDownloadable || (isDownloadable && filesCount === 0),
      icon: <Download className="w-4" />,
    },
    {
      key: 'delete',
      label: <div>{t('dashboard.albums.action.delete.title')}</div>,
      icon: <Trash className="w-4" />,
    },
  ];

  const onClickItems = ({ key }: { key: string }) => {
    switch (key) {
      case 'edit':
        setModalOpen({ edit: true });
        break;
      case 'share':
        setIsModalOpenShare(true);
        break;
      case 'download':
        setModalOpen({ download: {} });
        break;
      case 'delete':
        setModalOpen({
          delete: {
            type: 'confirm',
            title: t('dashboard.albums.action.delete.confirm'),
            btn: t('dashboard.albums.action.delete.title'),
            handleClick: handleDetele,
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown
        menu={{
          items,
          onClick: onClickItems,
        }}
        trigger={['click']}
        onOpenChange={(open: boolean) => {
          if (open) refetchAlbum();
        }}
      >
        <div
          className="p-1"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <MoreHorizontal className="w-5" />
        </div>
      </Dropdown>
      <MessageModal
        isVisible={!!modalOpen?.delete}
        onClose={() => {
          setModalOpen({ delete: null });
          if (deleteAlbum.isSuccess)
            dispatch(setIsDeleteSuccess(!isDeleteSuccess));
        }}
        handleClick={modalOpen?.delete?.handleClick}
        title={modalOpen?.delete?.title || ''}
        type={modalOpen?.delete?.type}
        btnText={modalOpen?.delete?.btn}
      />
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t('dashboard.albums.action.download.modalDownload.title')}
          </div>
        }
        open={!!modalOpen?.download}
        onCancel={() => {
          setModalOpen({ download: null });
        }}
        footer={null}
        destroyOnClose={true}
      >
        <DownloadAlbum
          totalPhotos={album?.file_count}
          id={album?.id}
          onClick={() => {
            setModalOpen({ download: null });
          }}
        />
      </Modal>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t('dashboard.albums.action.download.modalShare.title')}
          </div>
        }
        open={isModalOpenShare}
        onCancel={() => {
          setIsModalOpenShare(false);
        }}
        footer={null}
      >
        <ShareAlbum albumId={albumId} />
      </Modal>
      <Modal
        title={
          <div className="text-primary font-semibold text-3xl">
            {t('formEditAlbum.title')}
          </div>
        }
        open={modalOpen?.edit}
        onCancel={() => {
          setModalOpen({ edit: false });
        }}
        footer={null}
      >
        <EditAlbum
          album={album}
          onClick={() => {
            setModalOpen({ edit: false });
          }}
        />
      </Modal>
    </div>
  );
};

export default ActionAlbum;
