import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import googleLogo from "../../assets/images/google-logo.svg";
import { t } from "i18next";
import { useLoginApi } from "../../services/apis/auth.api";
import { Dispatch, SetStateAction } from "react";

export default function GoogleLogin({
	setError,
}: {
	setError: Dispatch<SetStateAction<string>>;
}) {
	const mutation = useLoginApi();

	const onFinish = (
		token: Omit<TokenResponse, "error" | "error_description" | "error_uri">
	) => {
		mutation.mutate(
			{ google_token: token.access_token },
			{
				onError: () => {
					setError(t("formLogin.loginFail"));
				},
			}
		);
	};
	const loginGoogle = useGoogleLogin({
		onSuccess: onFinish,
		onError: () => setError(t("formLogin.loginGoogleFail")),
		onNonOAuthError: () => setError(t("formLogin.loginGoogleFail")),
	});
	return (
		<button
			className="flex gap-2 items-center py-[10px] px-2 bg-white hover:border-secondary border border-slate-900/30 transition-all text-slate-700 rounded-md w-full justify-center mt-4 font-medium"
			onClick={() => loginGoogle()}
		>
			<img src={googleLogo} alt="google-logo" />
			{t("formLogin.loginGg")}
		</button>
	);
}
